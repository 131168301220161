import { Component, EventEmitter, OnInit, Output, AfterViewInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { FileService } from '../../services/file.service';
import { MatCheckbox, MatRadioButton, MatDialog, MatSelect, MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { ThousandSeparatorPipe } from 'src/app/pipes/thousand-separator.pipe';
import { FileUploader } from 'ng2-file-upload';
import { SharedService } from 'src/app/services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProcessService } from 'src/app/services/process.service';
import { saveAs } from 'file-saver';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, of, timer } from 'rxjs';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { debounceTime, delay, distinctUntilChanged, map, startWith, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DropdownConfigurationService } from 'src/app/services/dropdownConfiguration.service';
// tslint:disable-next-line:no-duplicate-imports 
@Component({
  selector: 'tas-table',
  templateUrl: './tas-table.component.html',
  styleUrls: ['./tas-table.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at 
    // the component level here, due to limitations of our example generation script. 
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing 
    // `MatMomentDateModule` in your applications root module. We provide it at the component level 
    // here, due to limitations of our example generation script.  
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  changeDetection: ChangeDetectionStrategy.Default
})

export class TasTableComponent implements OnInit {
  @ViewChild('street_name', { static: false }) streetName: ElementRef;
  curlyBrackets = '{{'
  subscriptionUpdateForm: any;
  @Input() processKey;
  @Input() processId;
  @Input() processName;
  @Input() processYear;
  @Input() stageKey;
  @Input() stageData;
  @Input() globals = {}
  @Output() onSelectedValue = new EventEmitter<any>();
  @Output() onRowKeyValue = new EventEmitter<any>();
  @Output() layerEmitter = new EventEmitter<any>();
  @Input() stage: any;
  @Input() selectedBlk: any;
  @Input() isSubmitted: any;
  @Input() reportId: any;
  @Input() mcasreportId: any;
  dataSourceOld = [];
  dataSources = [];
  originalData = [];
  categoryTableData: any;
  categoryTableColumns = [];
  originalDisplayedColumns = null;
  inputValues = {};
  streetIds = {};
  attachments = {};
  backingData = [];
  requiredFields = [];
  stageOutput: any;
  globalsOutput: any;
  multiColumn: boolean;
  editMode = false;
  thisYear = new Date().getFullYear();
  mCategories = []
  multiColumns = []
  categoryHeader = ""
  isExtendable = {}
  bbox
  dropdowns = {
    'sector dropdown': of([]),
    'branch dropdown': of([]),
    'activity dropdown': of([]),
    'activity detail dropdown': of([]),
    'region dropdown': of([]),
    'department dropdown': of([]),
    'city dropdown': of([]),
    'municipality dropdown': of([]),
    'district dropdown': of([]),
    'locality dropdown': of([])
  }

  data = {
    'thisYear': this.thisYear,
    'previousYear': this.thisYear - 1,
    'previousYearProcesses': {
      'dsf': {}
    },
    'processes': {
      'dsf': this.inputValues
    }
  };
  columnNames = {
    'name': 'Name',
    'key': 'Value'
  };

  columnStyles = {
  };

  columnNames2 = {
    'name': 'Name',
    'key': 'Value'
  };
  header_created = false;

  backingDataCounter = 0;

  clr = 1
  allowedMimeType = ['.xls', '.xlsx', '.doc', '.docx', '.pdf', 'image/jpeg', 'image/png'];
  public uploader: FileUploader;
  fileNames = {};
  fetched = 0
  fileNameLinks = {};
  fileInDocflow = {};
  public uploaders = {};
  subscriptionLanguage: any;
  showNoOptionsMessage: boolean = false;

  constructor(public sharedService: SharedService,
    public dropdownConfigurationService: DropdownConfigurationService,
    public translateService: TranslateService,
    public dictionaryService: DictionaryService,
    public http: HttpClient,
    private spinner: NgxSpinnerService, public processService: ProcessService, public dialog: MatDialog, private _adapter: DateAdapter<any>) {
    this.subscriptionLanguage = sharedService.getLan$().subscribe(lan => {
      if (lan) {
        if (lan === 'fr') {
          this._adapter.setLocale('fr');
        }
        if (lan === 'en') {
          this._adapter.setLocale('en-US');
        }

      }
    })
    this.filteredOptions = this.inputChanged.pipe(
      startWith(''),
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(value =>
        this.dictionaryService.fetchStreets(value, this.layerID).pipe(
          tap(options => this.showNoOptionsMessage = this.shouldShowNoOptions(options)),
          delay(500)
        )
      )
    );
    this.sharedService.tableChangeEmitter.subscribe(val => {
      this.inputValues = val
    })
  }
  shouldShowNoOptions(options: any[]): boolean {

    let show = (options && options.length === 0 && this.streetName.nativeElement.value !== '') || options.filter(elem => elem.name !== this.streetName.nativeElement.value).length > 0
    this.fetched++;
    return show
  }
  getColor() {
    if (this.clr === 1) {
      this.clr = 0
      return "#e6e6e6"
    }
    this.clr = 1
    return "lightgrey"
  }
  companyGroup: any;
  totalFee = 0
  async processStageStructure() {
    for (const block of this.stage.blocks) {
      var dataSource1 = [];
      this.originalData = [];
      var displayedColumns = ['name'];
      var upperHeaderColumns = [];
      var upperHeaderColumns2 = [];
      if (block.columns === undefined || block.columns.length === 0) {
        for (const row of block.rows) {
          if (row.type === 'total fee') {
            this.processService.getAvis(this.processId).subscribe(res => {
              this.totalFee = res
            })
          }
          if (row.configDropdown){
            let dropdowns = await this.dropdownConfigurationService.getDropdown(this.processYear, row.configDropdown).toPromise()
            row.selectItems = dropdowns
          }
          console.log(row)
          let orig = { name: row.name, key: row.key, default: row.default, enabled: row.default, style: row.style, type: row.type, required: row.required, global: row.global, hasAttachment: row.hasAttachment, configDropdown: row.configDropdown}
          if (row.selectItems) {
            orig['selectItems'] = row.selectItems
          }
          if (row.condition) {
            orig["condition"] = row.condition;
          }
          if (row.customValueEmitter) {
            orig["customValueEmitter"] = row.customValueEmitter;
            if (this.stageKey !== row.customValueEmitter.stageKey)
              this.processService.getEmmiterValue(row.customValueEmitter, this.processId).subscribe(res => {
                if (res) {
                  this.inputValues[row.key] = res[row.customValueEmitter.key].value
                  this.onChange(row, row.key, false, this.inputValues[row.key]);
                }
              })
          }
          this.originalData.push(orig);

          if (row.hasAttachment) {
            if (this.companyGroup !== null && row.condition !== undefined && row.key !== "annexDec" && row.condition.group !== undefined && row.condition.group === this.companyGroup.group) {
              row.requiredAttachment = true;
            }
            var uploader = new FileUploader({
              url: this.sharedService.apiUrl('upload'), authToken: "Bearer " + localStorage.getItem('access_token'),
              allowedFileType: ["xls", "xlsx", "pdf", "doc", "docx", 'image'],
              maxFileSize: 15 * 1024 * 1024,
              itemAlias: 'dec', additionalParameter: {
                'fileType': 'attachment', 'processId': this.processId, 'processYear': this.processYear,
                'processName': this.processName, 'stage': this.stage.key, 'row': row.key,
                'mcasreportId': this.mcasreportId ? this.mcasreportId : null, 'reportId': this.reportId !== null ? this.reportId : null
              }
            });
            uploader.onWhenAddingFileFailed = (item, filter) => {
              uploader.clearQueue();
              this.fileNames[row.key] = undefined
              if (filter.name === 'fileSize') {
                this.openFileSizeLimitExceededDialog();
              }

            }
            uploader.onAfterAddingFile = (fileItem) => {
              this.fileNames[row.key] = (fileItem.file.name);
              this.uploaders[row.key]['options']['additionalParameter']['stagedata'] = this.stageOutput

            }
            uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
              this.uploaders[row.key].clearQueue();
              this.fileNames[row.key] = undefined
              const resp = JSON.parse(response)
              this.attachments[row.key] = { 'file': resp['file'], 'filename': resp['filename'] }

              this.spinner.hide();
            };
            this.uploaders[row.key] = uploader
          }
          if (this.stageData !== undefined) {
            var v = this.stageData.rows.find(col => col[row.key] !== undefined && col[row.key] !== '');
            if (v !== undefined) {
              this.runDropdowns(row.type, row.key, v)
              this.inputValues[row.key] = v[row.key];
              this.stageOutput.rows.push({ [row.key]: v[row.key] });
              if (row.required !== undefined && row.required === true) {

                if (this.requiredFields.indexOf(row) !== -1)
                  this.requiredFields.splice(this.requiredFields.indexOf(row), 1)
              }
            }


          }
          if (row.global) {
            for (var rowG in this.globals) {
              if (rowG === row.key) {
                this.inputValues[row.key] = this.globals[rowG]

                if (this.stageOutput.rows.find(el => el[row.key] !== undefined)) {
                  this.stageOutput.rows.find(el => el[row.key] !== undefined)[row.key] = this.globals[rowG]
                } else {
                  this.stageOutput.rows.push({ [row.key]: this.globals[rowG] });
                }
              }
            }
          }
          if (row.required !== undefined && row.required === true) {
            if (this.inputValues[row.key] === undefined || !this.inputValues[row.key] || this.inputValues[row.key] === null || this.isEmptyOrSpaces(this.inputValues[row.key])) {
              this.requiredFields.push(row.key);
            }
          }
        }
        this.multiColumns[block.key] = false;
      } else {
        for (const row of block.rows) {
          const sourceRow = { name: row.name, key: row.key, default: row.default, enabled: row.default, style: row.style, bold: row.bold, condition: row.condition };
          this.inputValues[row.key] = {};
          if (this.stageData !== undefined) {
            var v = this.stageData.rows.find(cols => cols['key'] === row.key);
            if (v !== undefined) {
              for (const value of v.values) {
                var objKey = Object.keys(value)[0];
                this.inputValues[row.key][objKey] = value[objKey];
                var vxs = this.stageOutput.rows.find(cols => cols['key'] === row.key);
                if (vxs !== undefined) {
                  vxs.values.push({ [objKey]: value[objKey] });
                } else {
                  this.stageOutput.rows.push({ 'key': row.key, 'values': [{ [objKey]: value[objKey] }] });
                }
              }
            }
          }

          for (const value of row.values) {
            if (value.condition) {
              sourceRow["condition"] = value.condition;
            }
            if (value.customValueEmitter) {
              sourceRow["customValueEmitter"] = value.customValueEmitter;
              sourceRow["customValueEmitter"]["column"] = value.column;
            }
            if (sourceRow['required'] === undefined) {
              sourceRow['required'] = [];
            }
            sourceRow['required'][value.column] = row.required
            if (row.required !== undefined && row.required === true) {
              if (this.inputValues[row.key][value.column] === undefined
                || this.isEmptyOrSpaces(this.inputValues[row.key][value.column])) {
                this.requiredFields.push(row.key + "." + value.column);
              }
            }


            if (sourceRow['types'] === undefined) {
              sourceRow['types'] = [];
            }
            sourceRow['types'][value.column] = value.type

            if (sourceRow['fontWeight'] === undefined) {
              sourceRow['fontWeight'] = [];
            }
            sourceRow['fontWeight'][value.column] = value.bold ? 'bold' : value.fontWeight

            if (value.formula === undefined)
              sourceRow[value.column] = value.value;
            else {
              sourceRow[value.column] = value.formula
              sourceRow['style'] = 'bold'
            }

          }
          this.originalData.push(sourceRow);
        }
        for (const column of block.columns) {
          displayedColumns.push(column.key);
          this.columnNames[column.key] = column.name;
          this.columnStyles[column.key] = column.bold ? '800' : 'normal'
          if (column.isExtendable) {
            this.isExtendable[block.key] = true
          }
        }
        if (block.colType === "2") {
          displayedColumns.push("action#");
        }
        if (block.columnGroups !== undefined) {
          var grpCounter = 0;
          for (const column of block.columnGroups) {
            upperHeaderColumns[grpCounter] = column.name
            grpCounter++;
          }
        }
        if (block.columnGroups2 !== undefined) {
          var grpCounter = 0;
          for (const column of block.columnGroups2) {
            upperHeaderColumns2[grpCounter] = column.name
            grpCounter++;
          }
        }
        this.multiColumns[block.key] = true;
      }
      dataSource1 = this.originalData.filter(elem => {
        return elem.enabled === true;
      });
      if (block.colType === "2" && this.stageData) {
        for (let x = 0; x < this.stageData.rows.length; x++) {
          let r = this.stageData.rows[x];
          if (r.key) {
            let spl = r.key.split("#####");
            if (spl.length > 1 && block.key === spl[0]) {
              this.stageOutput.rows.push({ key: r.key, values: r.values });
              let result = r.values.reduce((obj, item) => {
                for (let key in item) {
                  obj[key] = item[key];
                }
                return obj;
              }, {});
              this.inputValues[r.key] = result;
              let cloneDatasource = Object.assign([], dataSource1);
              let m = cloneDatasource[cloneDatasource.length - 1];
              let newRow = Object.assign({}, m);
              newRow.key = r.key;
              cloneDatasource.push(newRow);
              dataSource1 = cloneDatasource;
            }
          }
        }
      }
      this.dataSources.push({
        'datasource': dataSource1,
        colType: block.colType,
        sumTotals: block.sumTotals,
        'displayedColumns': displayedColumns,
        'multiColumn': this.multiColumns[block.key], 'blockKey': block.key,
        'blockName': block.name, 'columnGroups': block.columnGroups,
        'upperHeaderColumns': upperHeaderColumns, 'columnGroups2': block.columnGroups2,
        'upperHeaderColumns2': upperHeaderColumns2, condition: block.condition
      });
    }
    this.stageOutput['status'] = this.requiredFields.length === 0 ? "done" : "processing"
    this.stageOutput['name'] = this.stage.name
    this.stageOutput['globals'] = this.globals ? this.globals : {}
    this.onSelectedValue.emit(this.stageOutput);
  }

  processPreviousYearJSON(data) {
    for (const stage of data.stages) {
      for (const row of stage.rows) {
        if (row.key === undefined) continue
        for (const value of row.values) {
          this.data.previousYearProcesses.dsf[row.key] = {}
          this.data.previousYearProcesses.dsf[row.key][Object.keys(value)[0]] = value[Object.keys(value)[0]];
        }
      }
    }
  }
  uploadAttachment(uploader) {
    this.spinner.show();
    uploader.uploadAll();
  }

  ngOnInit() {

    if (this.processId) {
      this.processService.getAttachments(this.processId, this.stageKey).subscribe
        (attachments => {
          this.attachments = {};
          attachments.forEach(att => {
            for (var key in att[this.stageKey]) {
              this.attachments[key] = att[this.stageKey][key]
            }
          })
          this.initialize()
        });
    } else {
      this.initialize()
    }
    this.dropdowns['sector dropdown'] = this.dictionaryService.getList('sector')
    this.dropdowns['region dropdown'] = this.dictionaryService.getList('dictionary/levels/1/CIV')

  }

  addDynamicRow(dt, blockKey) {
    let newKey = blockKey + "#####" + this.generateRandomString(10);
    this.inputValues[newKey] = {};
    let cloneDatasource = Object.assign([], dt.datasource);
    let m = cloneDatasource[cloneDatasource.length - 1];
    let newRow = Object.assign({}, m);
    newRow.key = newKey;
    cloneDatasource.push(newRow);
    dt.datasource = cloneDatasource;
  }
  removeAddable(dt, rowInd) {
    let cloneDatasource = Object.assign([], dt.datasource);
    let elk = cloneDatasource[rowInd].key;
    let sto = this.stageOutput.rows.find((elem) => elem.key === elk);
    if (sto) this.stageOutput.rows.splice(this.stageOutput.rows.indexOf(sto), 1);
    dt.datasource = [];
    cloneDatasource.splice(rowInd, 1);
    dt.datasource = cloneDatasource;
  }
  generateRandomString(length) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  }

  runDropdowns(type, key, val) {
    switch (type) {

      case 'sector dropdown':
        this.updateCorrespondingDropdown({ type, key }, { value: val.sector })
        break;

      case 'branch dropdown':
        this.updateCorrespondingDropdown({ type, key }, { value: val.branch })
        break;

      case 'activity dropdown':
        this.updateCorrespondingDropdown({ type, key }, { value: val.activity })
        break;

      case 'region dropdown':
        this.updateCorrespondingDropdown({ type, key }, { value: val.region })
        break;
      case 'department dropdown':
        this.updateCorrespondingDropdown({ type, key }, { value: val.department })
        break;
      case 'city dropdown':
        this.updateCorrespondingDropdown({ type, key }, { value: val.city })
        break;
      case 'municipality dropdown':
        this.updateCorrespondingDropdown({ type, key }, { value: val.municipality })
        break;
      case 'district dropdown':
        this.updateCorrespondingDropdown({ type, key }, { value: val.district })
        break;
      default:
        break;
    }

  }








  initialize() {
    this.stageOutput = {
      'key': this.stageKey,
      'rows': []
    };
    this.processStageStructure();
    this.onSelectedValue.emit(this.stageOutput);
  }

  clearFromUploads(rowKey) {
    this.fileNames[rowKey] = undefined
    this.uploaders[rowKey].clearQueue()
  }
  downloadFileFromDocFlow(fileObj) {
    this.spinner.show();
    this.processService.downloadFileFromDocflow(fileObj.file).subscribe
      (data => {
        this.spinner.hide();
        const blob = data;
        const file = new Blob([blob], {});
        const filename = fileObj.filename;
        saveAs(file, filename);
      });
  }

  deleteFileEmmitter: EventEmitter<boolean> = new EventEmitter();
  subscriptionConfirm: any
  openDeleteFileDialog(fileObj, rowId) {

    var prc = { p_id: this.processId, p_stage: this.stageKey, p_row: rowId }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { text: "DIALOG.PROCESS_DELETE_ATTACHMENT", emitter: this.deleteFileEmmitter, obj: fileObj.file }
    });

    this.subscriptionConfirm = this.sharedService.confirmDialogEmitter.subscribe(val => {
      if (val) {
        this.spinner.show()
        this.processService.deleteFile(val).subscribe
          (data => {
            this.processService.deleteAttachment(prc).subscribe(delRes => {
              this.attachments[rowId] = undefined
              this.spinner.hide()
            })
          });
      }
    })

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openFileSizeLimitExceededDialog() {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: '500px',
      data: { title: "DIALOG.ATTACHMENT_FILE_SIZE_EXCEEDED" }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }



  renderMustache(template, index, row, col) {
    if (!template) return
    if (template.indexOf('{{') === -1) return index === 0 ? "" : template
    const string = template.replace(
      /{{\s?([^{}\s]*)\s?}}/g,
      (substring, parsedKey) => {
        const replacer = parsedKey.split('.').reduce((o, i) => {
          if (o !== undefined) {
            return (o[i] && o[i].value) || o[i]
          }
        },
          this.inputValues)
        return typeof replacer !== 'undefined' && replacer !== '' && !isNaN(replacer) ? replacer : 0;
      }
    );
    if (string === template || string.indexOf('{{') !== -1) {
      return null;
    } else {
      try {

        var res = eval(string);
        if (isNaN(res)) {
          return res;
        }
        else {
          this.inputValues[row['key']][col] = res

          return res;
        }

      } catch (e) {
        return this.translateService.instant('PROCESS.SUBMITED_FILE_REPORT')
        // return 'Incorrect value in input field.'; 
      }
    }
  }
  a = 0;
  getToggleColor() {
    if (this.a === 0) {
      this.a = 1;
      return "#6699ff"

    }
    this.a = 0;
    return "white"
  }
  arrangeRequiredField(required, rowKey, event) {
    if (this.requiredFields.find(elem => elem === rowKey) !== undefined) {
      if (this.requiredFields.indexOf(rowKey) !== -1)
        this.requiredFields.splice(this.requiredFields.indexOf(rowKey), 1)
    }
    else if (required && (this.getEventValue(event) === null || this.isEmptyOrSpaces(this.getEventValue(event)))) {
      this.requiredFields.push(rowKey)
    }
  }
  private inputChanged = new Subject<string>();
  filteredOptions: Observable<string[]> | undefined;


  onInputChange(value: string): void {
    this.inputChanged.next(value);

  }
  getSum(col, dtInd) {
    let currentDatasource = this.dataSources[dtInd].datasource[0]
    if (!currentDatasource || currentDatasource['types'][col] !== 'number')
      return ''
    let sum = 0;
    this.stageOutput.rows.forEach(r => {
      if (r.values)
        r.values.forEach(val => {
          if (val[col]) {
            sum += val[col] * 1
          }
        })
    })
    return sum
  }
  getDataBuilding(val, dataSource) {
    this.dictionaryService.fetchMunicipality(val.id, null).subscribe(res => {
      this.layerID = res['id']
      this.layerEmitter.emit(this.layerID)
      this.inputValues['map_street_number_building_id'] = val.id
      this.onChange(dataSource.datasource.find(elem => elem.key === 'map_street_number_building_id'), dataSource.multiColumn, false, val.id)
      this.inputValues['map_street_number_location'] = null
      this.onChange(dataSource.datasource.find(elem => elem.key === 'map_street_number_location'), dataSource.multiColumn, false, null)
    })
  }
  getDataLocation(val, dataSource) {
    this.dictionaryService.fetchMunicipality(val.coordinates[0], val.coordinates[1]).subscribe(res => {
      this.layerID = res['id']
      this.layerEmitter.emit(this.layerID)
      this.inputValues['map_street_number_location'] = val.coordinates
      this.onChange(dataSource.datasource.find(elem => elem.key === 'map_street_number_location'), dataSource.multiColumn, false, val.coordinates)
      this.inputValues['map_street_number_building_id'] = null
      this.onChange(dataSource.datasource.find(elem => elem.key === 'map_street_number_building_id'), dataSource.multiColumn, false, null)
    })

  }
  getStreetId(val, dataSource) {
    this.inputValues['map_street_number_street_id'] = val
    this.onChange(dataSource.datasource.find(elem => elem.key === 'map_street_number_street_id'), dataSource.multiColumn, false, val)
  }
  onOptionSelected(val, dataSource) {
    this.inputValues['map_street_number_street_id'] = val.option.value.id
    this.inputValues['map'] = val.option.value.name
    this.onChange(dataSource.datasource.find(elem => elem.key === 'map_street_number_street_id'), dataSource.multiColumn, false, val.option.value.id)
    this.onChange(dataSource.datasource.find(elem => elem.key === 'map'), dataSource.multiColumn, false, val.option.value.name)
    this.showNoOptionsMessage = false
  }
  onChange(rowElem, col = null, multiColumn, event) {
    if (['dropdown', 'option'].includes(rowElem.type))
      this.setCustomValue(rowElem.key, event.value.value ? event.value.value : event.value.name)
    this.updateCorrespondingDropdown(rowElem, event)
    let row = rowElem['key']
    let required = col !== null ? (rowElem['required'][col] !== undefined ? rowElem['required'][col] : false) : (rowElem['required'] !== undefined ? rowElem['required'] : false)
    let pushed = false;
    let pushedGlobal = false;
    if (!multiColumn) {
      this.arrangeRequiredField(required, row, event)
      let val = this.getEventValue(event)
      //this.inputValues[rowElem['key']] = val
      for (const stageRow of this.stageOutput.rows) {
        if (stageRow[row] !== undefined) {
          stageRow[row] = this.getEventValue(event)
          pushed = true;
          if (val === null || val === '' || val === undefined) {
            this.stageOutput.rows.splice(this.stageOutput.rows.indexOf(stageRow), 1)
          }
          break;
        }
      }

      if (!pushed) {
        this.stageOutput.rows.push({
          [row]: this.getEventValue(event)
        });
      }

      if (rowElem['global']) {
        for (const stageRow of this.stageOutput.globals) {
          if (stageRow[row] !== undefined) {
            stageRow[row] = this.getEventValue(event);
            pushedGlobal = true;
            break;
          }
        }
        if (!pushedGlobal) {
          this.stageOutput.globals[row] = this.getEventValue(event)
        }
      }
      this.inputValues[rowElem['key']] = val
    } else {
      this.arrangeRequiredField(required, row + '.' + col, event)

      const rowToBeChanged = this.originalData.find(elem => {
        return elem.key === row;
      });

      var options = []
      if (rowToBeChanged)
        for (var x in rowToBeChanged.types) {
          if (rowToBeChanged.types[x] === 'option' && x !== col)
            options.push(x)
        }

      for (const stageRow of this.stageOutput.rows) {

        if (stageRow['key'] === row) {
          for (const stageValue of stageRow.values) {
            if (stageValue[col] !== undefined) {
              stageValue[col] = this.getEventValue(event);
              pushed = true;
            }
          }
          if (!pushed) {

            if (event.source instanceof MatRadioButton) {
              stageRow.values.forEach(val => {
                options.forEach(opt => {
                  if (val[opt] !== undefined) {
                    var idxx = stageRow.values.indexOf(val)
                    stageRow.values.splice(idxx, 1)
                  }
                })
              })
              stageRow.values.push({
                [col]: this.getEventValue(event)
              });
            } else {
              stageRow.values.push({
                [col]: this.getEventValue(event)
              });
            }
            pushed = true;
          }
        }
      }
      if (!pushed) {
        if (event.source instanceof MatRadioButton) {
          this.stageOutput.rows.push({
            'key': row,
            'values': [{
              [col]: this.getEventValue(event)
            }]
          });
        } else {
          this.stageOutput.rows.push({
            'key': row,
            'values': [{
              [col]: this.getEventValue(event)
            }]
          });
        }
      }
    }

    for (var obj in this.inputValues) {
      var val = this.inputValues[obj]
      if (this.isObject(val)) {
        var arr = this.stageOutput.rows.find(elem => elem['key'] === obj)
        if (arr)
          for (var keyInVal in val) {
            var elInArr = arr.values.find(elem => elem[keyInVal])
            let x = {}
            x[keyInVal] = val[keyInVal]
            if (!elInArr) {
              arr.values.push(x)
            } else {
              arr.values[arr.values.indexOf(elInArr)] = x
            }
          }
      }
    }

    if (rowElem.required && event.checked !== undefined && event.checked === false) {
      this.requiredFields.push(rowElem.key)
    }
    this.stageOutput['status'] = this.requiredFields.length === 0 ? "done" : "processing"
    this.stageOutput['name'] = this.stage.name
    this.onSelectedValue.emit(this.stageOutput);
    this.sharedService.changeTableEmit(this.inputValues)
  }
  public objectComparisonFunction = function (option, value): boolean {
    if (value)
      return option.key === value.key;
    return false;
  }
  checkVisibilityCondition(row) {
    if (row.condition && this.stageOutput.rows) {
      if (row.condition.condition === true) {
        let found = this.stageOutput.rows.find(elem => elem[row.condition.key] === true)
        let s = found && found[row.condition.key]
        if (!s) {
          let condKey = row.key
          {
            this.dataSources.forEach((dt) => {
              dt.datasource.forEach((r) => {
                if (r.key === condKey) {
                  this.inputValues[condKey] = false
                  this.stageOutput.rows[condKey] = false
                  this.onChange(r, condKey, dt.multiColumn, false);
                }
              });
            });
          }
        }
        return s;
      }
      let r = this.stageOutput.rows.find((elem) => elem[row.condition.key]);
      if (r) return row.condition.condition === true || row.condition.condition.length === 0 || row.condition.condition.includes(r[row.condition.key]["key"]);
      return true;
    }
    return !row.condition || row.condition.condition.length === 0;
  }
  checkVisibilityConditionBlock(block) {
    if (block.condition && this.stageOutput.rows) {
      let r = this.stageOutput.rows.find((elem) => elem[block.condition.key]);
      if (r) return block.condition.condition.length === 0 || block.condition.condition.includes(r[block.condition.key]["key"]);
      return true;
    }

    return true;
  }
  public objectComparisonFunctionID = function (option, value): boolean {
    if (value)
      return option.id === value.id;
    return false;
  }
  layerID;
  displayFn(street): string | undefined {
    if (street)
      return street.name || street
  }
  updateCorrespondingDropdown(rowElem, value) {
    if (['sector dropdown', 'branch dropdown', 'activity dropdown', 'activity detail dropdown',
      'region dropdown', 'department dropdown', 'city dropdown', 'municipality dropdown',
      'district dropdown', 'locality dropdown'].includes(rowElem['type'])) {
      if (rowElem.key === 'sector') {
        this.dropdowns['branch dropdown'] = this.dictionaryService.getDictionaryList('dictionary/sector/' + value.value.id + '/branch')
        this.dropdowns['activity dropdown'] = of([])
        this.dropdowns['activity detail dropdown'] = of([])
      }
      if (rowElem.key === 'branch') {
        this.dropdowns['activity dropdown'] = this.dictionaryService.getDictionaryList('dictionary/branch/' + value.value.id + '/activity')
        this.dropdowns['activity detail dropdown'] = of([])
      }
      if (rowElem.key === 'activity') {
        this.dropdowns['activity detail dropdown'] = this.dictionaryService.getDictionaryList('dictionary/activity/' + value.value.id + '/activityDetail')
      }
      if (rowElem.key === 'region') {
        this.bbox = value.value.bbox
        this.dropdowns['department dropdown'] = this.dictionaryService.getList('dictionary/levels/' + (value.value.level + 1) + '/' + value.value.id)
        this.dropdowns['city dropdown'] = of([])
        this.dropdowns['municipality dropdown'] = of([])
        this.dropdowns['district dropdown'] = of([])
        this.dropdowns['locality dropdown'] = of([])

      }
      if (rowElem.key === 'department') {
        this.bbox = value.value.bbox
        this.dropdowns['city dropdown'] = this.dictionaryService.getList('dictionary/levels/' + (value.value.level + 1) + '/' + value.value.id)
        this.dropdowns['municipality dropdown'] = of([])
        this.dropdowns['district dropdown'] = of([])
        this.dropdowns['locality dropdown'] = of([])
      }
      if (rowElem.key === 'city') {
        this.bbox = value.value.bbox
        this.dropdowns['municipality dropdown'] = this.dictionaryService.getList('dictionary/levels/' + (value.value.level + 1) + '/' + value.value.id)
        this.dropdowns['district dropdown'] = of([])
        this.dropdowns['locality dropdown'] = of([])
      }
      if (rowElem.key === 'municipality') {
        this.bbox = value.value.bbox
        this.layerID = value.value.id
        this.layerEmitter.emit(this.layerID)
        // this.dropdowns['district dropdown'] = this.dictionaryService.getList('dictionary/levels/' + (++value.value.level) + '/'+value.value.id)
        // this.dropdowns['locality dropdown'] = of([])
      }
      if (rowElem.key === 'district') {
        this.dropdowns['locality dropdown'] = this.dictionaryService.getDictionaryList('dictionary/district/' + value.value.id + '/locality')
      }
    }

  }
  isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
  }
  // edit() { 
  //   if (!this.editMode) { 
  //     this.editMode = true; 
  //     this.dataSourceOld = this.originalData; 
  //     this.originalDisplayedColumns = this.displayedColumns; 
  //     this.displayedColumns = ['default', 'name']; 
  //   } else { 
  //     this.editMode = false; 
  //     this.dataSourceOld = this.originalData.filter(elem => { 
  //       return elem.enabled === true; 
  //     }); 
  //     this.displayedColumns = this.originalDisplayedColumns; 
  //     this.originalDisplayedColumns = null; 
  //   } 
  // } 


  ngOnChanges(changes: SimpleChanges) {
    const stage: SimpleChange = changes.stage;
  }
  isEmptyOrSpaces(str) {
    const stringValue = str.toString();
    return stringValue === null || stringValue.match(/^ *$/) !== null;
  }

  checkboxClick(event, key) {
    const rowToBeChanged = this.originalData.find(elem => {
      return elem.key === key;
    });
    rowToBeChanged.enabled = event.checked;
    if (event.checked === false) {
      if (!this.multiColumn) {
        for (const row of this.stageOutput.rows) {
          if (row[key] !== undefined) {
            delete row[key];
          }
        }
      } else {
        for (let i = 0; i < this.stageOutput.rows.length; i++) {
          if (this.stageOutput.rows[i].key === key) {
            delete this.stageOutput.rows[i];
          }
        }
      }
      Object.keys(this.inputValues[key]).forEach(col => {
        this.inputValues[key][col] = 0;
      });
    }
  }

  onAmountChange(amount: string, name) {
  }

  getEventValue(event) {
    if (!event)
      return null
    var value
    if (event.source instanceof MatAutocomplete) {
      value = event.option.value.id
    }
    else if (event.source instanceof MatCheckbox) {
      value = event.checked
    }
    else if (event.source instanceof MatRadioButton || event.source instanceof MatSelect) {
      value = this.isCustom(event.value) || event.value
    }
    else if (event.target !== undefined) {
      value = event.target.value
    }
    else {
      value = event
    }
    return value
  }

  isCustom(matSelect) {
    const keysToCheck = ['branch', 'activity', 'activityDetail', 'department', 'city', 'municipality', 'district', 'locality'];
    const hasAnyKeyInMatSelect = keysToCheck.some(key => key in matSelect);
    if (hasAnyKeyInMatSelect) {
      return { id: matSelect.id, name: matSelect.name }
    } return null
  }

  printKey(key, col) {
    this.onRowKeyValue.emit("{{" + key + "." + col + "}}");
  }

  setCustomValue(key, value) {
    this.dataSources.forEach((dt) => {
      dt.datasource.forEach((r) => {
        if (r.customValueEmitter && r.customValueEmitter.key === key) {
          if (!dt.multiColumn) this.inputValues[r.key] = value;
          else this.inputValues[r.key][r.customValueEmitter.column] = value;
          this.onChange(r, r.customValueEmitter.column || null, dt.multiColumn, value);
        }
      });
    });
  }

} 
