import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from "../environments/environment";
import { AppComponent } from './components/app.component';
import { AppRoutingModule } from './modules/router/app-routing.module';
import { MultiStepFormComponent, SubmitReportProcessDialog } from './components/multi-step-form/multi-step-form.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatButtonModule, MatChipsModule, MatCheckboxModule, MatIconModule, MatStepperModule, MatTableModule, MatToolbarModule, MatDatepickerModule, MatMenuModule, MatPaginatorIntl,
  MatPaginatorModule, MatListModule, MatGridListModule, MatSlideToggleModule, MatNativeDateModule, MatAutocompleteModule, MatButtonToggleModule, MatRadioModule, MatCardModule, MatProgressSpinnerModule, MatSelectModule, MatDialogModule, MatInputModule, MatSortModule, MatTabsModule, MatDividerModule, MatExpansionModule
} from '@angular/material';
import { FlexLayoutModule } from "@angular/flex-layout";
import { CdkTableModule } from '@angular/cdk/table';
import { TasTableComponent } from './components/tas-table/tas-table.component';
import { MatFormFieldModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TasTopMenuComponent } from './components/tas-top-menu/tas-top-menu.component';
import { HomeComponent, NewProcessDialog, UploadFileDialog } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { JwtModule } from '@auth0/angular-jwt';
import { AuthService } from './services/auth.service';
import { ProcessService } from './services/process.service';
import { AuthGuard } from './guards/auth.guard';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { TasDatatableComponent } from './components/tas-datatable/tas-datatable.component';
import { SharedService } from './services/shared.service';
import { DevelopmentComponent } from './components/development/development.component';
import { DevelopmentService } from './services/development.service';
import { ProcessComponent } from './components/process/process.component';
import { CategoryTableComponent } from './components/form-components/category-table/category-table.component';
import { ProcessViewComponent, DeleteProcessDialog, SubmitProcessDialog, UploadExcelDialog } from './components/process/process-view/process-view.component';
import { ProcessStepComponent } from './components/process/process-step/process-step.component';
import { FormCreatorComponent } from './components/form-creation/form-creator/form-creator.component';
import { AdministrationComponent } from './components/settings/administration/administration.component';
import { ReportService } from './services/report.service';
import { MatPaginatorIntlTas } from './components/home/MatPaginatorIntlTas';
import { ProcessTypeService } from './services/proces-type.service';
import { AuthInterceptor } from './util/auth.interceptor';
import { HelpComponent } from './components/help/help.component';
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { ThousandSeparatorDirective } from './directives/thousandSeparator.directive';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { CompanyService } from './services/company.service';
import { UserService } from './services/user.service';
import { CompanyRepresentativeService } from './services/companyRepresentative.service';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ApidocComponent } from './components/help/apidoc/apidoc.component';
import { ApidocsService } from './services/apidocs.service';
import { OnlyNumber } from './directives/onlyNumberDirective';
import { ErrorpageComponent } from './components/errorpage/errorpage.component';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {FileUploadModule} from "ng2-file-upload";
import { InfoDialogComponent } from './components/dialogs/info-dialog/info-dialog.component';
import { ExternalFormsComponent } from './components/external-forms/external-forms.component';
import { YearService } from './services/year.service';
import { TinRequestComponent, UploadTinRequestFileDialog } from './components/tin-request/tin-request.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ProfileGuard } from './guards/profile.guard';
import { CategoryService } from './services/category.service';
import { BaseService } from './services/base.service';
import { DictionaryService } from './services/dictionary.service';
import { CityService } from './services/city.service';
import { SectorService } from './services/sector.service';
import { PostalCodeService } from './services/postalCode.service';
import { MultiselectAutocompleteComponent } from './components/multiselect-autocomplete/multiselect-autocomplete.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { DepartmentService } from './services/departmentService';
import { RegionService } from './services/region.service';
import { MunicipalityService } from './services/municipality.service';
import { BranchService } from './services/branch.service';
import { ActivityService } from './services/activity.service';
import { ActivityDetailService } from './services/activityDetail.service';
import { MapPreviewComponent } from "./components/map/map-preview.component";
import { MapService } from "./components/map/map.service";
import { MapComponent } from "./components/map/map.component";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { StreetAdderComponent } from './components/street-adder/street-adder.component';
import { AvisService } from './services/avis.service';
import { DropdownConfigurationService } from './services/dropdownConfiguration.service';
registerLocaleData(localeFr, 'fr');
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function tokenGetter() {
  return localStorage.getItem('access_token');
}


@NgModule({
  declarations: [
    AppComponent,
    MultiStepFormComponent,
    TasTableComponent,
    TasTopMenuComponent,
    NewProcessDialog,
    DeleteProcessDialog,
    UploadExcelDialog,
    UploadFileDialog,
    UploadTinRequestFileDialog,
    SubmitProcessDialog,
    HomeComponent,
    LoginComponent,
    TasDatatableComponent,
    DevelopmentComponent,
    ProcessComponent,
    CategoryTableComponent,
    ProcessViewComponent,
    ProcessStepComponent,
    FormCreatorComponent,
    AdministrationComponent,
    HelpComponent,
    ThousandSeparatorPipe,
    ThousandSeparatorDirective,
    OnlyNumber,
    ApidocComponent,
    ErrorpageComponent,
    ConfirmationDialogComponent,
    InfoDialogComponent,
    ExternalFormsComponent,
    SubmitReportProcessDialog,
    TinRequestComponent,
    UserProfileComponent,
    MultiselectAutocompleteComponent,
    FileUploaderComponent,
    MapComponent,
    MapPreviewComponent,
    StreetAdderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTableModule,
    CdkTableModule,
    MatStepperModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDividerModule,
    MatMenuModule,
    MatExpansionModule,
    FlexLayoutModule,
    LeafletModule,
    MatPaginatorModule,
    MatGridListModule,
    MatListModule,
    MatInputModule,
    MatSortModule,
    MatRadioModule,
    MatCardModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    CurrencyMaskModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatTabsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    LeafletDrawModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [environment.whitelistDomain]
      }
    }),
    NgxMaskModule.forRoot(),
    FileUploadModule
  ],
  providers: [AuthService, DropdownConfigurationService,
    AuthGuard, ProcessService, ProfileGuard, AvisService, ProcessTypeService, ReportService, SharedService, PostalCodeService, MapService,
    DevelopmentService, CompanyService, CategoryService, CityService, BaseService, DictionaryService, SectorService, BranchService, ActivityService, ActivityDetailService, DepartmentService, RegionService, MunicipalityService, UserService, ApidocsService,CompanyRepresentativeService,YearService,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlTas },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ThousandSeparatorPipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [NewProcessDialog, DeleteProcessDialog, SubmitProcessDialog, ConfirmationDialogComponent, UploadExcelDialog, UploadTinRequestFileDialog, UploadFileDialog, InfoDialogComponent, SubmitReportProcessDialog]
})
export class AppModule { }
