import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from './shared.service';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class DropdownConfigurationService extends BaseService{

   httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(public http: HttpClient, public sharedService: SharedService) {
    super(http, sharedService);
   }
   getDropdown(year, dropdown) {
    let processTypes$ = this.http.get<any>(this.sharedService.apiUrl('dropdown-configuration/'+year +'/'+ dropdown))
    return processTypes$;
}
}



